<template>
  <div class="pay_content">
    <headerTop></headerTop>
    <!-- 主体 -->
    <div class="header bg1">
      <div class="wrap">
        <div @click="GoIndex()" class="fl">
          <img src="@/assets/image/logo_shape@2x.png" alt />
          联众医药
          <span>收银台</span>
        </div>
      </div>
    </div>
    <div class="content bgw">
      <div class="wrap title">
        订单提交成功，请尽快付款 订单号：{{out_trade_no}}
        <div class="fr">
          应付金额
          <span>{{money}}</span> 元
        </div>
      </div>
      <div v-show="state==0" class="wrap box">
        <div v-if="pay_type == 'alipayScan'" class="titles">支付宝</div>
        <div v-if="pay_type == 'wechatScan'" class="titles">微信支付</div>
        <div v-if="pay_type == 'bankpay'" class="titles">银联支付</div>
        <!-- <div class="top">
          二维码已过期,
          <span>刷新</span> 重新获取二维码
        </div>-->
        <div class="top"></div>
        <div class="pay_imgs">
          <div id="qrcode" ref="qrcode"></div>
        </div>
        <div v-if="pay_type == 'alipayScan'" class="pay_txt">请用支付宝扫码支付</div>
        <div v-if="pay_type == 'wechatScan'" class="pay_txt">请用微信扫码支付</div>
        <div v-if="pay_type == 'bankpay'" class="pay_txt">银联支付</div>
        <div @click="state=1" class="tabPay">选择其他支付方式</div>
      </div>
      <div v-show="state==1" class="wrap box">
        <div class="titles mb">选择支付方式</div>
        <div @click="tabPay='wechatScan'" :class="{tabActive:tabPay=='wechatScan'}" class="tab1">
          <img
            v-show="tabPay!='wechatScan'"
            class="img1"
            src="@/assets/image/Cashier_Choice_Unchecked@2x.png"
            alt
          />
          <img
            v-show="tabPay=='wechatScan'"
            class="img1"
            src="@/assets/image/Cashier_Choice_Selection@2x.png"
            alt
          />
          <img class="img2" src="@/assets/image/Cashier_wixin_icon@2x.png" alt />
          微信支付
        </div>
        <div @click="tabPay='alipayScan'" :class="{tabActive:tabPay=='alipayScan'}" class="tab1">
          <img
            v-show="tabPay!='alipayScan'"
            class="img1"
            src="@/assets/image/Cashier_Choice_Unchecked@2x.png"
            alt
          />
          <img
            v-show="tabPay=='alipayScan'"
            class="img1"
            src="@/assets/image/Cashier_Choice_Selection@2x.png"
            alt
          />
          <img class="img2" src="@/assets/image/Cashier_zhifubao_icon@2x.png" alt />
          支付宝支付
        </div>
        <div
          v-if="noYue ==1"
          @click="tabPay='bankpay'"
          :class="{tabActive:tabPay=='bankpay'}"
          class="tab1"
        >
          <img
            v-show="tabPay!='bankpay'"
            class="img1"
            src="@/assets/image/Cashier_Choice_Unchecked@2x.png"
            alt
          />
          <img
            v-show="tabPay=='bankpay'"
            class="img1"
            src="@/assets/image/Cashier_Choice_Selection@2x.png"
            alt
          />
          <img class="img2" src="@/assets/image/Cashier_wallet_icon@2x.png" alt />
          银联支付
        </div>
        <button @click="confirm()" class="confirm">确定</button>
      </div>
    </div>
    <el-dialog title="余额支付" :visible.sync="dialogVisible" width="50%">
      <span>是否确认从银联内付款 {{money}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="payFily()">取 消</el-button>
        <el-button type="primary" @click="tabSend()">确 定</el-button>
      </span>
    </el-dialog>
    <div class="bg1">
      <publicBottom></publicBottom>
    </div>
  </div>
</template>
<script>
import headerTop from "@/components/public/public_headerTop.vue";
import publicBottom from "@/components/public/public_bottom";
import QRCode from "qrcodejs2";
import { go_pay } from "@/request/index"; //支付
import { getPayStatus } from "@/request/index"; //支付查询
export default {
  components: {
    headerTop,
    publicBottom
  },
  data() {
    return {
      state: 0,
      tabPay: "",
      money: "", // 付款金额
      out_trade_no: "", //订单号
      pay_type: "", //支付方式
      pay_img: "", //跳转地址
      dialogVisible: false, // 余额支付
      checkingPay: false, //支付验证
      noYue: 1 //不使用余额支付
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }
    this.money = this.$route.query.money;
    this.out_trade_no = this.$route.query.out_trade_no;
    this.pay_type = this.$route.query.pay_type;
    this.tabPay = this.$route.query.pay_type;
    let payType = this.$route.query.state;
    if (payType == 0) {
      this.noYue = 0;
    }

    //  判断是不是 余额支付
    if (this.pay_type == "bankpay") {
      // this.dialogVisible = true;
    } else {
      this.start();
    }
    this.init();
  },
  methods: {
    init() {
      let Athis = this;
      if (this.checkingPay == false) {
        setTimeout(function() {
          getPayStatus({
            token: Athis.$tokens,
            out_trade_no: Athis.out_trade_no
          }).then(res => {
            if (res.code == 1) {
              Athis.checkingPay = true;
              Athis.$router.replace({
                path: `./payResuly?resuly=1&orderId=${Athis.out_trade_no}`
              });
            }
          });
          Athis.init();
        }, 1000);
      }
    },
    // 选择支付 的 弹出 确定按钮
    tabSend() {
      this.dialogVisible = false;
      this.starts();
    },
    //  选择 支付
    confirm() {
      this.state = 0;
      this.pay_type = this.tabPay;
      if (this.pay_type == "balance") {
        this.dialogVisible = true;
      } else {
        this.start();
      }
    },
    //生成二维码
    qrcodeScan() {
      let qrcode = new QRCode("qrcode", {
        width: 302, // 二维码宽度
        height: 302, // 二维码高度
        text: this.pay_img
      });
    },
    //  微信 和 二维码支付 形成二维码 链接
    start() {
      go_pay({
        token: this.$tokens,
        pay_type: this.pay_type,
        money: this.money,
        out_trade_no: this.out_trade_no
      }).then(res => {
        this.pay_img = res.data.qr;
        this.qrcodeScan();
      });
    },
    payFily() {
      this.dialogVisible = false;
      this.$router.replace({
        path: `./payResuly?resuly=0&orderId=${this.out_trade_no}`
      });
    },
    //  账户余额支付
    starts() {
      go_pay({
        token: this.$tokens,
        pay_type: this.pay_type,
        money: this.money,
        out_trade_no: this.out_trade_no
      }).then(res => {
        if (res.code == 1) {
          this.$router.replace({
            path: `./payResuly?resuly=1&orderId=${this.out_trade_no}`
          });
        } else {
          this.$router.replace({
            path: `./payResuly?resuly=0&orderId=${this.out_trade_no}`
          });
        }
      });
    },
    GoIndex() {
      this.$router.replace({ path: "/" });
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/index";
</style>